<template>
<svg role="presentation" class="svg-icon svg-airplane" viewBox="0 0 48.248676 57.976875" xmlns="http://www.w3.org/2000/svg">
 <g transform="translate(-514.66 -602.51)">
  <path d="m548.16 602.51c-3.769-0.045-5.7207 2.5277-6.3314 5.8056-0.89729 6.5226-0.16949 14.779-0.83089 21.163-7.9423 0.30967-12.135 0.50341-18.716 0.84152-0.03782-8.1357-0.59466-9.8631-3.0374-9.9946-2.4061-0.12951-2.8148 1.8912-2.8826 3.8926s-0.04371 4.2098-0.08833 6.3846c1e-5 -1e-5 -1.1939 0.0105-1.4296 0.39247-0.21913 0.35507-0.25627 1.1118-0.07333 1.5101 0.16893 0.36778 1.5396 0.49704 1.5396 0.49705s0.01614 4.1001-0.04208 6.1233c-0.06754 2.2094 0.08008 4.3004 2.9883 4.3579s2.9982-4.5109 3.0254-10.11c8.7664 0.54888 9.2537 0.63724 18.716 1.0967 0.61641 6.3572-0.0664 13.451 0.83089 18.967 0.71772 4.4118 2.5637 7.1714 6.3185 7.0459s5.0898-2.2592 5.0962-7.0792c0.01096-8.5204 0.77108-18.125 0.77108-18.125 3.714-0.08257 8.8734 0.84901 8.8879-2.8289s-5.1471-3.0097-8.8962-3.129c-9.1e-4 -4.5626-0.82378-14.532-0.78702-21.1 0.01792-3.073-1.2532-5.6643-5.0591-5.7097z" fill-rule="evenodd"/>
 </g>
</svg>

</template>
<style scoped>
    path {
        fill: white;
    }
</style>